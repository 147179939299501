import React from 'react';
import {Box, ButtonBase, Paper, Typography,makeStyles} from '@material-ui/core';
import {formatDate, formatPeso} from "../../helpers";
import Strings from "../../assets/strings";
import {HtmlTooltip} from "../index";
const useStyles = makeStyles(theme => ({
    root: {
        // boxShadow: "none",
        display: "block",
        margin:"6px 8px",
        // borderBottom: "1px solid" +theme.palette.grey[300],
        border: "1px solid #c7c7c7",
        borderRadius: 4,

        "&.c":{
            background:"rgba(216, 67, 21, 0.13)",
            "& .estado":{
                color: "#d84315",
            }
        },
        "&.m":{
            background:"rgba(255, 111, 0, 0.05)",
            "& .estado":{
                color: "#ff6f00",
            }
        },
        "&.p":{
            background:"rgba(25, 118, 210, 0.07)",
            "& .estado":{
                color: "#1976d2",
            }
        },
        "& .content":{
            padding:theme.spacing(1),
            // [theme.breakpoints.only('xs')]: {
            //   overflow: "auto",
            // },
        }
    },
    divider:{
        margin: theme.spacing(1) +"px 0px",
    },
    details:{
        "&>div":{
            display:"flex",
            justifyContent:"space-between",
            [theme.breakpoints.down(400)]: {
                "&:first-child":{
                    flexDirection:"column-reverse",
                },
                flexDirection: "column"
            }

        }
    }

}));
export default function ItemInmueble({ onClick,item }) {
    const classes = useStyles();
    return (
        <>
            {item &&
            <ButtonBase component={Paper} className={classes.root +" "+item.conEstadoDescripcion?.charAt(0)?.toLowerCase()} onClick={onClick}>
                <div className="content" >
                    <Box display="flex" justifyContent="space-between">
                        <Typography component={"span"} noWrap>{item.cliNombre}</Typography>
                        <HtmlTooltip
                            title={Strings.monto+" "+Strings.cuotas }
                        >
                            <Typography align={"right"}>
                                {/*<Typography variant="caption">Cuotas:</Typography>*/}
                                {formatPeso(item.conMontoApagar)}
                            </Typography>
                        </HtmlTooltip>

                    </Box>
                    <div style={{margin:"8px 0",borderBottom:"1px dashed #cacaca"}}/>
                    <div className={classes.details}>
                        <div>
                            <Typography variant="caption" noWrap>{Strings.contrato}: {item.conReferencia}</Typography>
                            <Typography variant="body2">{
                                formatDate(item.conFechaProximoPago,"DD MMMM, YYYY")
                                    .replace(", "+new Date().getFullYear(),"")
                            }</Typography>
                        </div>
                        <div>
                            <Typography variant="caption" noWrap>Inmueble: {item.inmReferencia}</Typography>
                            <Typography variant="caption" noWrap>{Strings.cuotas} {Strings.pendiente}: {item.conCuotasPendiente}</Typography>
                        </div>
                    </div>
                </div>
            </ButtonBase>
            }
        </>
    )
}
