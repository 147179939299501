import React, {useEffect, useState} from 'react';
import {Box, Dialog, DialogContent, useMediaQuery} from '@material-ui/core';
import {connect} from "react-redux";
import {mostrarGenerales} from "../../redux/actions/generalesActions";
import {formatDate, formatPeso, geoString} from "../../helpers";
import {isMobile} from "../../helpers/browser_detect";
import {makeStyles} from "@material-ui/core/styles";
import {MoreVert} from "@material-ui/icons";
import {useHistory, useLocation} from "react-router-dom";
import TopBarDialog from "../topBar/TopBarDialog";
//import SignatureCanvas from 'react-signature-canvas'
import Strings from "../../assets/strings";
import html2canvas from "html2canvas";
import {shareBase64} from "../../helpers/downloadFiles"
import ButtonSelect from "../ButtonSelect";
import moment from "moment";
// const useStyles = makeStyles(theme => ({
//
//     sigCanvas:{
//         touchAction: "none",
//         position: "absolute",
//         bottom: "-14px",
//         right: "-18px"
//     },
//     close:{
//         marginRight: "auto",
//     },
//     reciboPago: {
//         minWidth: 1040,
//         margin: "0 auto",
//         padding: "20px 25px",
//         fontFamily: "arial",
//         fontSize: "14px",
//         color: "#000",
//         position: "relative",
//         outline: "2px solid",
//         border: "2px solid",
//         outlineColor:theme.palette.primary.main,
//         borderColor:theme.palette.primary.light,
//         "&:nth-child(2) ": {
//             display:"none",
//             position: "relative",
//             marginTop: "70px",
//             borderColor: "rgb(209, 209, 209)",
//             outlineColor: "#aaaaaa",
//             "&>:before": {
//                 content: '""',
//                 position: "absolute",
//                 top: "-37px",
//                 left: "0",
//                 width: "100%",
//                 borderBottom: "1px dashed"
//             },
//             '@media print': {
//                 display:"block",
//             }
//         },
//         "& :not(i) span": {
//             //paddingRight: "7px",
//             fontWeight: "normal"
//         },
//         "&.small":{
//             // border: "none",
//             // outline: "none",
//             // padding: 0,
//             minWidth: 300,
//
//             '& .topHead':{
//                 textAlign: "left",
//                 display: "block",
//                 height: "auto",
//                 "& img":{
//                     maxHeight: 72,
//                     display:"none",
//                 },
//                 "& .info *:not(h1)":{
//                     fontSize: ".8rem",
//                 }
//             },
//             '& .header':{
//                 flexDirection: "column",
//                 "& h1":{
//                     order: 1,
//                     textAlign: "center",
//                     fontSize: "1.5em",
//                     marginBottom: 14,
//                     fontWeight: "normal",
//                 },
//                 "& .numero":{
//                     order: 2,
//                     marginBottom: 8,
//                 },
//                 "& .fecha":{
//                     order: 3,
//                     "& div.top":{
//                         display: "initial",
//                         borderBottom: "1px solid",
//                         padding: "0px 10px",
//                         "& span": {
//                             padding: 3,
//                             borderBottom: "none",
//
//                         }
//                     },
//
//                     "& div.bottom":{
//                         display:"none",
//                     },
//
//                 },
//             },
//             '& section':{
//               "& p":{
//                   margin: "8px 0px",
//               }
//             },
//             '& .desglose':{
//                 display: "block",
//                 "&>div":{
//                     padding: 0,
//                     border: "none",
//                     marginBottom: 8,
//                     "&>div":{
//                         display: "inline-block",
//                         marginRight: 13,
//                         textTransform: "uppercase",
//                     },
//                 }
//             },
//             '& .footer':{
//                 flexDirection: "column",
//                 alignItems: "baseline",
//                 "& .firma":{
//                     margin: "0 auto",
//                     marginTop: 50,
//                 }
//             }
//         },
//         "& .d-print-none":{
//             opacity: 0.5,
//         }
//     },
//     desglose:{
//         display:"flex",
//         "&>div":{
//             position: "relative",
//             marginRight: "18px",
//             paddingLeft: "5px",
//             borderLeft: "1px solid"
//         }
//     },
//     topHead: {
//         display: "flex",
//         justifyContent: "space-between",
//         textAlign: "right",
//         height: "103px",
//         "& h1":{
//             color:theme.palette.primary.main,
//             fontWeight: "normal",
//             marginBottom: "0",
//             fontSize: "1.3rem",
//         },
//         "& .logo":{
//             maxWidth: 180,
//             textAlign: "left",
//             "& img":{
//                 height: "100%",
//             },
//         },
//         "& span":{
//             padding: 0,
//             "&.tel":{
//                 fontSize:".8rem",
//             },
//             "&.dir":{
//                 fontSize:".7rem",
//             }
//         }
//     },
//     header:{
//         display: "flex",
//         justifyContent: "space-between",
//         marginBottom: "20px",
//         "& .numero": {
//             fontWeight: "bold",
//             "& span": { borderBottom: "1px solid", padding: "0px 7px", fontWeight: "normal" }
//         },
//         "& h1": { margin: "0", fontSize: "1.8em", color:theme.palette.primary.main },
//         "& div.fecha": {
//             "& div.top": {
//                 "& span":{
//                     borderBottom: "1px solid",
//                     padding: "0 7px",
//                 }
//             },
//             "& div.bottom": {
//                 fontSize: ".8em",
//                 fontStyle: "italic",
//                 "& span":{
//                     padding: "0 9px",
//                 }
//             },
//
//         }
//     },
//     section: {
//         marginBottom: 1,
//         "& p": {
//             display: "flex",
//             "& span": {
//                 fontWeight: "bold",
//
//                 "&:last-child, &.suma": {
//                     fontWeight: "normal",
//                     borderBottom: "1px solid #ccc",
//                     padding: "0px 7px",
//                     flex: 12,
//                 },
//
//                 "&.suma": {
//                     flex: 70,
//                 }
//             },
//             "&.cRestante": {
//                 textAlign: "right",
//
//                 "& span": {
//                     width: 134,
//                     textAlign: "left",
//                 }
//             }
//         }
//     },
//     content:{
//         background:"white",
//         padding:3,
//     },
//     footer: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         "& .firma": {
//         marginTop: 53,
//         position: "relative",
//         "& .imgFirma": {
//             maxHeight: "86px",
//             position: "absolute",
//             top: "-82px",
//             textAlign: "center",
//             width: "100%",
//             "& img": {
//                 height: 84,
//             }
//         },
//         "& span": {
//             fontWeight: "bold",
//             padding: "3px 90px",
//             border: "none",
//             borderTop: "1px solid"
//         }
//     }
//     },
//     '@media print': {
//         root:{
//             "&>div":{
//                 background:"white",
//             }
//         },
//         content:{
//             overflow: 'visible',
//             margin:0,
//         },
//         appBar:{
//             display:"none",
//         }
//     },
//
// }));
const useStyles = makeStyles((theme) => ({
    root:{
        '@media print': {
            "&>div":{
                background:"white",
                width:"1020px",
            }
        }
    },
    askShare:{
        padding: 5,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        '@media print': {
            display:"none"
        }
    },
    pagoPediente:{
        "&>div":{
            display:"flex",
            padding:theme.spacing(1),
            borderBottom: "1px solid #e6e6e6",
            "&>p:first-child":{
                fontWeight: 500,
                marginRight:theme.spacing(1)
            }
        }
    },
    sigCanvas:{
        touchAction: "none",
        position: "absolute",
        bottom: "-14px",
        right: "-18px"
    },
    close:{
        marginRight: "auto",
    },
    reciboPago: {
        minWidth: 1040,
        margin: "0 auto",
        padding: "20px 25px",
        fontFamily: "arial",
        fontSize: "14px",
        color: "#000",
        position: "relative",
        // outline: "2px solid",
        border: "2px solid",
        // outlineColor:theme.palette.primary.main,
        borderColor:theme.palette.primary.main,
        "&.aju":{
            // outlineColor:theme.palette.secondary.main,
            borderColor:theme.palette.secondary.main,
            "& h1": {
                color:theme.palette.secondary.main +"!important"
            },
        },
        "&:nth-child(2) ": {
            display:"none",
            position: "relative",
            marginTop: "70px",
            borderColor: "rgb(209, 209, 209)",
            outlineColor: "#aaaaaa",
            "&>:before": {
                content: '""',
                position: "absolute",
                top: "-37px",
                left: "0",
                width: "100%",
                borderBottom: "1px dashed"
            },
            '@media print': {
                display:"block",
            }
        },
        "& :not(i) span": {
            //paddingRight: "7px",
            fontWeight: "normal"
        },
        "&.small":{
            // border: "none",
            // outline: "none",
            // padding: 0,
            minWidth: 300,
            '& .topHead':{
                textAlign: "center",
                display: "block",
                height: "auto",
                '& .logo':{
                    textAlign: "center",
                    margin: "0 auto",
                    "& img":{
                        maxWidth: "73px",
                        maxHeight: "73px",
                    },
                },

                "& .info":{
                    "& h1":{
                        marginTop:4
                    },
                    "& *:not(h1)":{
                        fontSize: ".8rem",
                    }
                }
            },
            '& .header':{
                flexDirection: "column",
                "& h1":{
                    //order: 1,
                    textAlign: "center",
                    marginBottom: 14,
                    fontWeight: "normal",
                },
                "& .numero":{
                    //order: 2,
                    marginBottom: 8,
                },
                "& .fecha":{
                    // order: 3,
                    "& div.top":{
                        display: "initial",
                        borderBottom: "1px solid",
                        padding: "0px 10px",
                        "& span": {
                            padding: 3,
                            borderBottom: "none",

                        }
                    },

                    "& div.bottom":{
                        display:"none",
                    },

                },
            },
            '& section':{
                "& p":{
                    margin: "8px 0px",
                }
            },
            '& .desglose':{
                display: "block",
                "&>div":{
                    padding: 0,
                    border: "none",
                    marginBottom: 8,
                    "&>div":{
                        display: "inline-block",
                        marginRight: 13,
                        textTransform: "uppercase",
                    },
                }
            },
            '& .footer':{
                flexDirection: "column",
                alignItems: "baseline",
                "& .firma":{
                    margin: "0 auto",
                    marginTop:32
                }
            },
        },
        // "&.small":{
        //     // border: "none",
        //     // outline: "none",
        //     // padding: 0,
        //     minWidth: 300,
        //     '& .topHead':{
        //         textAlign: "center",
        //         display: "block",
        //         height: "auto",
        //         '& .logo':{
        //             textAlign: "center",
        //             margin: "0 auto",
        //             "& img":{
        //                 maxWidth: "73px",
        //                 maxHeight: "73px",
        //             },
        //         },
        //
        //         "& .info":{
        //             "& h1":{
        //                 marginTop:4
        //             },
        //             "& *:not(h1)":{
        //                 fontSize: ".8rem",
        //             }
        //         }
        //     },
        //     '& .header':{
        //         flexDirection: "column",
        //         "& h1":{
        //             order: 1,
        //             textAlign: "center",
        //             fontSize: "1.5em",
        //             marginBottom: 14,
        //             fontWeight: "normal",
        //         },
        //         "& .numero":{
        //             order: 2,
        //             marginBottom: 8,
        //         },
        //         "& .fecha":{
        //             order: 3,
        //             "& div.top":{
        //                 display: "initial",
        //                 borderBottom: "1px solid",
        //                 padding: "0px 10px",
        //                 "& span": {
        //                     padding: 3,
        //                     borderBottom: "none",
        //
        //                 }
        //             },
        //
        //             "& div.bottom":{
        //                 display:"none",
        //             },
        //
        //         },
        //     },
        //     '& section':{
        //         borderTop: "1px dashed",
        //         paddingTop: 6,
        //         borderBottom: "1px dashed",
        //         paddingBottom: 6,
        //         marginBottom: 10,
        //
        //
        //       "& p":{
        //           margin: "8px 0px",
        //           "& span:last-child":{
        //               borderBottom:"none"
        //           }
        //       }
        //     },
        //     '& .desglose':{
        //         display: "block",
        //         "&>div":{
        //             padding: 0,
        //             border: "none",
        //             marginBottom: 4,
        //             "&>div":{
        //                 display: "inline-block",
        //                 marginRight: 13,
        //                 //textTransform: "uppercase",
        //                 "&:after":{
        //                     content: "':'"
        //                 }
        //             },
        //             "&>span":{
        //                 fontStyle: "italic"
        //             },
        //         }
        //     },
        //     '& .footer':{
        //         flexDirection: "column",
        //         alignItems: "baseline",
        //         "& .firma":{
        //             margin: "0 auto",
        //         }
        //     },
        // },
        "& .d-print-none":{
            opacity: 0.5,
        }
    },
    desglose:{
        display:"flex",
        "&>div":{
            position: "relative",
            marginRight: "18px",
            paddingLeft: "5px",
            borderLeft: "1px solid"
        }
    },
    topHead: {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "right",
        height: "103px",
        "& h1":{
            color:theme.palette.primary.main,
            fontWeight: "normal",
            marginBottom: "0",
            fontSize: "1.3rem",
        },
        "& .logo":{
            maxWidth: 180,
            textAlign: "left",
            "& img":{
                maxWidth: "100%",
                maxHeight: "100%",
            },
        },
        "& span":{
            padding: 0,
            "&.tel":{
                fontSize:".8rem",
            },
            "&.dir":{
                fontSize:".7rem",
            }
        }
    },
    header:{
        // display: "flex",
        // justifyContent: "space-between",
        marginBottom: "20px",
        "& .numero": {
            fontWeight: "bold",
            "& span": { borderBottom: "1px solid", padding: "0px 7px", fontWeight: "normal" }
        },
        "& h1": { margin: "0", fontSize: "1.3em", color:theme.palette.primary.main, marginBottom:1},
        "& div.fecha": {
            "& div.top": {
                "& span":{
                    borderBottom: "1px solid",
                    padding: "0 7px",
                }
            },
            "& div.bottom": {
                fontSize: ".8em",
                fontStyle: "italic",
                "& span":{
                    padding: "0 9px",
                }
            },

        }
    },
    section: {
        marginBottom: 1,
        "& p": {
            fontSize: 14,
            display: "flex",
            "& span": {
                fontWeight: "bold",

                "&:last-child, &.suma": {
                    fontWeight: "normal",
                    borderBottom: "1px solid #ccc",
                    padding: "0px 7px",
                    flex: 12,
                },

                "&.suma": {
                    flex: 70,
                }
            },
            "&.cRestante": {
                textAlign: "right",

                "& span": {
                    width: 134,
                    textAlign: "left",
                }
            }
        }
    },
    content:{
        background:"white",
        padding:0,
        '@media print': {
            overflow: 'visible',
            margin:0,
        }
    },
    footer: {
        marginTop:16,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .firma": {
            marginTop: 38,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            "& .imgCont": {
                textAlign: "center",
                height: 69,
                "& img": {
                    height:"100%"
                },
            },
            "& span": {
                fontWeight: "bold",
                padding: "3px 90px",
                border: "none",
                borderTop: "1px solid"
            }
        }
    },
}));

function ReciboPago(props){
    let history = useHistory();
    const {state:urlState,pathname} = useLocation();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
    const classes = useStyles();

    const {mostrarGenerales,generales = {},toInmIcon} = props
    const [dLarge,setDLarge] = useState(!isMobile.anyWebView())
    const [data,setData] = useState({
        pagId: 0,
        conId: 0,
        cliente:"",
        pagFecha: new Date(),
        pagMonto: 0,
        pagTotal:0,
        pagDescuento: 0,
        pagMora: 0,
        pagNuevoCapital: 0,
        pagBalance: 0,
        pagComentario: "",
        pagMontoLetra: "",
        pagAnulado: true,
        pagTipo: 0,
        pagFormaPago: 0,
        pagConcepto: "",
        pagCuotaPagadas: 0,
        pagBalancePendiente: 0,
        pagCapitalPendiente: 0,
        pagFechaCreacion: new Date(),
        pagFechaAnulacion: new Date(),
    });

    useEffect(()=>{
        if(urlState?.recibo){
            mostrarGenerales();
            setData(urlState.recibo)
        }else{
            setData(null)
        }
    },[mostrarGenerales,urlState])

    if(!data) return null;
    let formatoRecibo;
    try {
        formatoRecibo = generales.ajuFormatoRecibo.split(",");
    }
    catch(error) {
        formatoRecibo = []
    }
    const imprimir = () => {
        let {ajuEmpresa :ajuEmpresaNombre, ajuEmpresaDireccion, ajuEmpresaTelefono, ajuEmpresaTelefono2, ajuFormatoRecibo} = generales;

        let obj = {
            ...data,
            ajuEmpresaNombre, ajuEmpresaDireccion, ajuEmpresaTelefono, ajuEmpresaTelefono2, ajuFormatoRecibo};
        let c_obj = {
            ...obj,
            pagFecha: formatDate(obj.pagFecha),
            pagFechaCreacion: formatDate(obj.pagFechaCreacion),
            pagFechaAnulacion: formatDate(obj.pagFechaAnulacion),
            pagFechaProximoPago: formatDate(obj.pagFechaProximoPago),
            pagBalance: formatPeso(obj.pagBalance),
            pagBalancePendiente: formatPeso(obj.pagBalancePendiente),
            pagMonto: formatPeso(obj.pagMonto),
            pagCapitalPendiente: formatPeso(obj.pagCapitalPendiente),
            pagComision: formatPeso(obj.pagComision),
            pagDescuento: formatPeso(obj.pagDescuento),
            pagMora: formatPeso(obj.pagMora),
            pagNuevoCapital: formatPeso(obj.pagNuevoCapital),
            pagTotal: formatPeso(obj.pagTotal),
        }



        if(window.ReactNativeWebView ){

            let ms = {
                action: "reciboPago",
                payload:  c_obj
            }

            return window.ReactNativeWebView.postMessage(JSON.stringify(ms))

        }else if(window.webkit?.messageHandlers?.printAPP){
            return window.webkit?.messageHandlers?.printAPP?.postMessage(c_obj);

        }else if(window.Android?.downloadFromBase64){
            return  window.Android?.printAPP(JSON.stringify(c_obj));

        }else{
            window.print()
        }



        // try {
        //     if (isMobile.AndroidWebView()) {
        //         window.Android.printAPP(JSON.stringify(c_obj));
        //     }else if(isMobile.IosWebView()){
        //         window.webkit.messageHandlers.printAPP.postMessage(c_obj);
        //     }else{
        //         window.print()
        //     }
        // }catch (e) {
        //     console.log(e);
        // }
    };
    // const convertHtml=()=>{
    //   //  window.scrollTo(0,0);
    //     html2canvas(document.querySelector(".reciboCont>div"),{scrollY:0, letterRendering: 1, allowTaint : true,logging:true,useCORS:true}).then(canvas => {
    //         let base64 = canvas.toDataURL('image/jpeg', 1.0);
    //         let fileName = "pago_"+data.cliente.replace(/\s/g,"-")+"_"+formatDate(data.pagFecha,"DD,MMMM,YYYY")+"_#"+data.pagId+"-"+data.conId+".jpg";
    //
    //         try {
    //             if (isMobile.AndroidWebView()) {
    //                 if(window.Android.shareFromBase64){
    //                     window.Android.shareFromBase64(base64.split(',')[1],fileName);
    //                 }
    //             }else if(isMobile.IosWebView()){
    //                 window.webkit.messageHandlers.shareFromBase64.postMessage(base64.split(',')[1],fileName);
    //             }else{
    //                 downloadBase64(base64,fileName)
    //             }
    //         }catch (e) {
    //             console.log(e);
    //         }
    //     }, 'image/jpeg', 1);
    // }
    const convertHtml=(whatsapp)=>{
        // setAnchorElMenu(null);
        //  window.scrollTo(0,0);
        //   let fileName = "pago_" + data?.cliente?.replace(/\s/g, "-") + "_" + formatDate(data?.fecha, "DD,MMMM,YYYY") + "_#" + data?.id;

        // let descripcion = `Saludos ${data?.cliente}, Su pago a sido aplicado exitosamente, Gracias.`

        // if(!!reciboPdf){
        //     if(whatsapp){
        //         return openShareMsj({base64:reciboPdf, descripcion})
        //
        //
        //
        //         // return socket.emit("sendMsg",{
        //         //     clientId:userId,
        //         //     number:numeroContacto,
        //         //     message:`Saludos ${data?.cliente}, Su pago a sido aplicado exitosamente, Gracias.`,
        //         //     base64:reciboPdf
        //         // })
        //     }
        //
        //     shareBase64(reciboPdf, "Pago de "+data?.cliente, "pdf")
        // }


        let el = document.querySelector(".reciboCont>div");
        if(el) {
            html2canvas(el, {
                scrollY: 0,
                //  letterRendering: 1,
                allowTaint: true,
                logging: true,
                useCORS: true,
                scale:whatsapp ? 3: undefined,
                // width:1024


            }).then(canvas => {


                if(whatsapp){
                    let MAX_WIDTH = 1024;
                    let MAX_HEIGHT = 1024;

                    let width = canvas.width;
                    let height = canvas.height;

                    // Change the resizing logic
                    if (width > height) {
                        // if (width > MAX_WIDTH) {
                        height = height * (MAX_WIDTH / width);
                        width = MAX_WIDTH;
                        // }
                    } else {
                        // if (height > MAX_HEIGHT) {
                        width = width * (MAX_HEIGHT / height);
                        height = MAX_HEIGHT;
                        // }
                    }


                    let canvasResized = document.createElement("canvas");

                    canvasResized.setAttribute('width', width.toString());
                    canvasResized.setAttribute('height', height.toString());

                    let ctx = canvasResized.getContext('2d');

                    ctx.drawImage(canvas, 0, 0, width, height);
                    //   var dataURL = extra_canvas.toDataURL();


                    // return socket.emit("sendMsg",{
                    //      clientId:userId,
                    //      number:numeroContacto,
                    //      message:`Saludos ${data?.cliente}, Su pago a sido aplicado exitosamente, Gracias.`,
                    //      base64
                    //  })
                }

                let base64 = canvas.toDataURL('image/jpeg', 1.0);



                shareBase64(base64, "Pago de "+data?.cliente, "jpg")
            });
        }
    }
    return(
        <>
            <Dialog //Boolean(data.cliId > 0)
                open={Boolean(data)}
                maxWidth={dLarge?"lg":"sm"}
                fullWidth={true}
                onClose={history.goBack}
                fullScreen={smVewport}
                className="dialogRecibo"
                classes={{root:classes.root}}
            >
                <TopBarDialog title={Strings.recibo} onClose={()=> history.replace(pathname)} >
                    <ButtonSelect smIcon={<MoreVert/>}
                                  opcions={[...[
                                      {key:"",value:Strings.opciones,disabled:true},
                                      {key:"1",value:Strings.imprimir},
                                      {key:"2",value:isMobile.anyWebView()?Strings.compartir:Strings.descargar+" jpg"},
                                      {key:"3",value:dLarge ? Strings.vertical:Strings.horizontal},
                                  ],...(toInmIcon ? [{key:"4",value:Strings.ver+" "+Strings.contrato}]:[]),
                                      {key:"5",value:Strings.ajustes}]}
                                  onChange={(v)=>{
                                      switch (v){
                                          case "1": imprimir()
                                              break
                                          case "2": convertHtml()
                                              break
                                          case "3": setDLarge(!dLarge)
                                              break
                                          case "4": history.push(`/contrato/${data.conId}`)
                                              break
                                          case "5": history.push("/ajustes#recibo")
                                              break
                                          default: return false
                                      }
                                  }}
                    />


                </TopBarDialog>
                <DialogContent className={classes.content}>
                    <div className="reciboCont">
                        {[...Array(formatoRecibo.includes("copia") && dLarge ?2:1).keys()].map(i=>
                            <div key={i} className={classes.reciboPago + (!dLarge?" small":"")}>
                                <div className={"topHead " +classes.topHead}>
                                    <div className="logo">
                                        {generales.ajuEmpresaLogo &&
                                            <img alt="logo" src={generales.ajuEmpresaLogo} />
                                        }
                                    </div>
                                    <div className="info">
                                        <h1>{generales.ajuEmpresa}</h1>
                                        <span className="tel">
                                            <i>
                                                {!!generales.ajuEmpresaRNC && <><span>{geoString("rnc",true)}: {generales.ajuEmpresaRNC}</span> <br/></>}
                                                {!!generales.ajuEmpresaTelefono && <span> Tel: {generales.ajuEmpresaTelefono}</span>}
                                                {!!generales.ajuEmpresaTelefono2 && <span> &nbsp;/ &nbsp; {generales.ajuEmpresaTelefono2}</span>}
                                            </i>
                                        </span>
                                        <br/>
                                        <span className="dir">{generales.ajuEmpresaDireccion}</span>

                                    </div>
                                </div>
                                <br />
                                <div className={"header " +classes.header}>
                                    <h1 style={{textAlign:"center"}}>{Strings.reciboDePago.toLocaleUpperCase()}</h1>


                                    <Box display={"flex"} justifyContent={"space-between"}>

                                    <div className="numero">{!dLarge && <>Recibo </>} No. <span>{data.pagId} - {data.conId} </span></div>
                                    <div className="fecha">
                                        {!dLarge && <strong>{Strings.fecha}: &nbsp;</strong>}

                                        <span style={{borderBottom: "1px solid"}}>
                                            <span>{formatDate(data?.pagFecha,"DD")}</span> /
                                            <span> {formatDate(data?.pagFecha,"MM")}</span> /
                                            <span> {formatDate(data?.pagFecha,"YYYY")} </span>
                                            <small> {moment(data?.pagFecha).format("hh:mm a")} </small>
                                        </span>

                                       {/* <div className={"top"}>*/}
                                       {/*     <span>{formatDate(data.pagFecha,"DD")}</span> /*/}
                                       {/*     <span>{formatDate(data.pagFecha,"MM")}</span> /*/}
                                       {/*     <span>{formatDate(data.pagFecha,"YYYY")}</span>*/}
                                       {/* </div>*/}
                                       {/*<div className={"bottom"}>*/}
                                       {/*    <span>{Strings.dia}</span>*/}
                                       {/*    <span>{Strings.mes}</span>*/}
                                       {/*    <span>{Strings.anio}</span>*/}
                                       {/*</div>*/}
                                    </div>
                                    </Box>
                                </div>
                                <section className={classes.section}>
                                    <p><span>{(Strings.recibido+" "+(data.pagTipo===4?Strings.por :Strings.de)).toLocaleUpperCase()}:</span> <span><b>{data.cliente}</b></span></p>
                                    <p><span>{Strings.laSumaDe.toLocaleUpperCase()}:</span>
                                    {dLarge && <><span className="suma">{data.pagMontoLetra}</span><span>{Strings.monto.toLocaleUpperCase()}</span></> }
                                    <span> {formatPeso(data.pagTotal)}</span></p>
                                    <p><span>{Strings.porConceptoDe.toLocaleUpperCase()}:</span> <span style={{width: "82.6%", display: "inline-block"}}>pago cuota del {data.pagConcepto}</span></p>
                                    {!!data.pagNota &&
                                        <p><span>{Strings.comentario.toLocaleUpperCase()}:</span> <span>{data.pagNota}</span></p>
                                    }


                                </section>
                                <div className={"footer " +classes.footer}>
                                    <div className={"desglose "+classes.desglose}>

                                        {formatoRecibo.includes("agente") && !!data?.creadoPor &&
                                            <div>
                                                <Box>{Strings.cobrador}:</Box>
                                                <span className="c">{data?.creadoPor}</span>
                                            </div>
                                        }


                                        {data.pagTipo !== 4 && <>
                                            {formatoRecibo.includes("descuento") &&
                                            <div>
                                                <Box>{Strings.descuento}:</Box>
                                                <span className="c">{formatPeso(data.pagDescuento)}</span>
                                            </div>}
                                            {formatoRecibo.includes("mora") &&
                                            <div>
                                                <Box>{Strings.mora}:</Box>
                                                <span className="c">{formatPeso(data.pagMora)}</span>
                                            </div>}
                                        </>}
                                        {formatoRecibo.includes("pendiente") &&
                                        <div>
                                            <Box>{data.pagMontoPendiente < 0 ?Strings.balancePendiente:"Acumulado"}:</Box>
                                            <span className="c">{formatPeso(data.pagMontoPendiente)}</span>
                                        </div>}
                                        {formatoRecibo.includes("proximoPago") &&
                                        <div>
                                            <Box>{Strings.proximo} {Strings.pago}:</Box>
                                            <span className="c">{formatDate(data.pagFechaProximoPago)}</span>
                                        </div>}
                                    </div>
                                    {formatoRecibo.includes("firma") &&
                                    <div className="firma">
                                        {/*<SignatureCanvas penColor='black'*/}
                                        {/*                 canvasProps={{width: 310, height: 100, className: classes.sigCanvas}} />*/}
                                        <span>{Strings.firma.toLocaleUpperCase()}</span>
                                    </div>}
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}


const mapStateToprops = (data) => ({
    generales: data.generales.data
})
export default connect(mapStateToprops,{mostrarGenerales})(ReciboPago);
