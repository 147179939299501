import React, {useState} from 'react';
import {API_PUBLIC} from '../../services';
import {Link, Redirect, useHistory, useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import {mostrarPerfil} from '../../redux/actions/authentication';
import {TextField, Button, Typography, CircularProgress,IconButton,InputAdornment,makeStyles} from '@material-ui/core';
import styles from "../../assets/jss/login";
import Strings from "../../assets/strings";
import {getLSLang} from "../../helpers";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {isMobile} from "../../helpers/browser_detect";
const useStyles = makeStyles(styles);

function Login(props){
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();

    const backurl = (urlState && urlState.backurl) ? urlState.backurl:"/inicio";
    const [showPass, setShowPass] = useState(false);

    const [error, setError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [state, setState] = useState({
        lang:getLSLang("l"),
        user:"",
        password:"",
        recordar:false,
    });
    const handleChange = (e)=>{
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };
    // const handleChangeBox = name => event => {
    //     setState({...state, [name]: event.target.checked });
    // };
    const submitForm = (e) =>{
        e.preventDefault();
        setLoading(true);
        setError("");
        API_PUBLIC.post('LogIn',{
            usuInicioSesion: state.user, usuClave: state.password
            // recordar: state.recordar,
        })
            .then((response) =>{
                localStorage.setItem("_token", response.data.token);


                // sen token to webview
                if(isMobile?.reactNative()){
                    let ms = {
                        action: "logIn",
                        payload:  response.data
                    };

                    window.ReactNativeWebView.postMessage(JSON.stringify(ms))
                }

                props.mostrarPerfil(response.data); // to get the color en currency
                history.replace(backurl)
            })
            .catch(({response}) => {
                if(response && response.data && typeof response.data === "string")
                    setError(response.data);
                else
                    setError("Error");
                setLoading(false)
            });
    };

    const classes = useStyles();

    const {auth } = props;
    if ((auth.isAuthenticated && localStorage.getItem("_token"))) {
        return <Redirect to={backurl}/>;
    }
    return(
        <div className={classes.root}>
            <div className={classes.logo}>
                <Typography variant="h1"  color="primary">GestInmueble</Typography>
                <Typography variant="h3" >{Strings.slogan}</Typography>
            </div>
            <form  onSubmit={submitForm} className={classes.form}>
                <Typography  className={classes.headline}>{Strings.iniciarSesion.toUpperCase()}</Typography>
                {!!error &&
                <Typography color="error" variant="caption">
                    {error}
                </Typography>
                }
                <div>
                    <TextField
                        label={Strings.nombreDeUsuario}
                        required
                        margin="normal"
                        name="user"
                        value={state.user}
                        onChange={handleChange}
                        fullWidth
                        className={"noAsterisk"}
                    />
                    <TextField
                        label={Strings.contrasena}
                        required
                        margin="normal"
                        name="password"
                        type={showPass?"text":"password"}
                        value={state.password}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment:  <InputAdornment position="end" style={{position: "absolute",right: 0}}>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>setShowPass(!showPass)}
                                    //  onMouseDown={handleMouseDownPassword}
                                >
                                    {showPass ? <Visibility fontSize={"small"}/> : <VisibilityOff fontSize={"small"} />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                        className={"noAsterisk"}
                        fullWidth
                    />
                    {/*<FormControlLabel className={classes.checkCont}*/}
                    {/*    control={*/}
                    {/*        <Checkbox*/}
                    {/*            checked={state.recordar}*/}
                    {/*            onChange={handleChangeBox('recordar')}*/}
                    {/*            value="recordar"*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*    label={Strings.recodarUsuario}*/}
                    {/*/>*/}
                </div>
                <Button
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    className={classes.submitBtn}
                    type="submit"
                >
                    {isLoading? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; Cargando</span>
                    ):(
                        <span>{Strings.entrar}</span>
                    )}
                </Button>
                <Link to="/forgotPassword" className={classes.contraLink}>{Strings.olvideMicon}</Link>
            </form>
            <Typography className={classes.bmLink}>{Strings.noCuenta} <Link to="/register">{Strings.registrarme}</Link></Typography>

            {/*<div className={classes.lang}>*/}
            {/*    <select value={state.lang} onChange={(e)=>{*/}
            {/*        let lang = e.target.value;*/}
            {/*        setLSLang(lang);*/}
            {/*        Strings.setLanguage(lang);*/}
            {/*        setState({...state,lang});*/}
            {/*    }}>*/}
            {/*        {Strings.getAvailableLanguages().map(l=>*/}
            {/*            <option key={l} value={l}>{Strings[l]}</option>*/}
            {/*        )}*/}
            {/*    </select>*/}
            {/*    /!*<label>{Strings.idioma}</label>*!/*/}
            {/*</div>*/}
            {!!error &&
            <Button className={classes.moreInfo}
                    component={"a"} variant="contained"
                    disableElevation color="default"
                    href={"https://gestinmueble.com/?p=true"}
            >
                Información del Sistema
            </Button>
            }
        </div>
    )

}
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(mapStateToProps, {mostrarPerfil })(Login);
