import React, {useEffect, useState} from 'react';
import { MostrarError } from "../../components/MostrarError";
import {useDispatch, useSelector} from 'react-redux';
import { mostrarInmuebleById,toggleInmueblePublico } from '../../redux/actions/inmuebleActions';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';

import ButtonSelect from "../../components/ButtonSelect";

import {formatPeso, formatDate, hasPermissionTo, PERSMISOS} from '../../helpers';
import GLightbox from 'glightbox';
import "glightbox/dist/css/glightbox.min.css";
import CancelDialog from './CancelDialog'

import {AppBar, LoadingProgress} from "../../components";
import {Button, Grid, Hidden, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import defaultInmueble from "../../img/defaultInmueble.jpg"
import {GoogleMap, Marker} from "@react-google-maps/api";
import Strings from "../../assets/strings";
import {RoomOutlined,BarChart,CallMade,VisibilityOutlined,VisibilityOffOutlined} from '@material-ui/icons';
import pdfSvg from "../../assets/img/pdf.svg";
import IngresosChart from "../../components/homeWidget/IngresosChart";
const useStyles = makeStyles((theme)=>({
    root: {
        overflow: "hidden"
    },
    map:{
        background: theme.palette.grey[100],
        "&>div":{
            width: '100%',
            minHeight: 324,
            height: '100%',
            // [theme.breakpoints.only('xs')]: {
            //     minHeight: 151,
            // },
        }
    },
    imgAvatarCont:{
        position: "relative",
        borderBottom: "1px solid "+theme.palette.grey[300],
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: 324,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&>img":{
            maxWidth: "100%",
        },
        "&:before":{
            content:"''",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            background: "linear-gradient(0deg, rgb(0 0 0 / 28%) 0%, rgba(0,0,0,0) 100%)",
            height: 41,
        },
        "& .precio":{
            position: "absolute",
            right: "5px",
            textAlign: "right",
            bottom: "5px",
            zIndex: 1,
            "& div.published":{
                background:theme.palette.secondary.main,
                color: "#fff",
                padding: "3px 10px",
                fontSize: ".9rem",
                marginBottom:3,
                display: "inline-block"
            }
        },
        background: theme.palette.grey[200],
        textAlign: "center",
        [theme.breakpoints.only('xs')]: {
            height: 268,
            marginBottom: -21,
        },
    },
    controls:{
        border: "1px solid "+theme.palette.grey[300],
        background: theme.palette.grey[100],
        display: "flex",
        justifyContent: "space-between",
        "&+div":{
            padding: `0 ${theme.spacing(1)}px`
        }
    },
    dirCont:{
        display: "flex",
        alignItems: "center",
        "& a":{
            textDecoration: "none",
            color:"inherit"
        }
    },
    docs: {
        margin: 0,
        marginTop: 10,
        padding: 0,
        listStyle: "none",
        display: "flex",
        "& li": {
            width: 50,
            marginLeft: theme.spacing(2),

            "& img": {
                width: "100%",
            }
        }
    },
    info:{
        "& .datos":{
            lineHeight: 1.5,
        },
        "& .carac ul":{
            paddingLeft: 21,
            listStyleType: "circle"
        }
    }
}))
export default function ModalDetalles(){
    const { inmId:urlId} = useParams();
    const history = useHistory();
    const {state:urlState} = useLocation();
    const [showChart, setShowChart] = useState(false)

    const classes = useStyles();
    const {byId:inmueble,isFetching,isPending,error } = useSelector(s=>s.inmuebles);
   // const userName = useSelector(s=>s.auth.user.usuInicioSesion);
    const Dispatch = useDispatch();


    useEffect(()=>{
        Dispatch(mostrarInmuebleById(urlId));
    },[Dispatch,urlId])

    const zoomImg = (e) => {
        const { adjuntos = [] } = inmueble;
        let images = adjuntos.filter(adj => adj.fileExt !== "pdf");

        if (!images.length) return false;

        var elements = images.map(function (item) {
            return {
                href: item.url.replace("-small",""),
                type: "image"
            };
        })
        const lightbox = GLightbox({
            elements
        });
        lightbox.open();
    }
    const changeEstado= (e) => {
        Dispatch(toggleInmueblePublico(urlId))
    }

    const images = inmueble.adjuntos?.filter(adj => adj.fileExt !== "pdf") ?? [];
    const docs = inmueble.adjuntos?.filter(adj => adj.fileExt === "pdf") ?? [];

    const imgAvatar = images.length ? images[0].url.replace("-small","") : defaultInmueble;



    const getLatLng = ()=>{
            let latLng = {lat:0, lng:0}
            let latlongArr = inmueble.inmCoordenadas?.split(",");

            if(latlongArr?.length === 2){
                latLng = {
                    lat: parseFloat(latlongArr[0]),
                    lng: parseFloat(latlongArr[1])
                }
            }
            return latLng
        }

    return (
        <>
            <AppBar title={"Detalles"}>
                    <ButtonSelect smIcon={<CallMade/>}
                                  opcions={[
                                      {key:"",value:Strings.ver +":",disabled:true},
                                      {key:"/pagos/"+inmueble.inmId,value:Strings.pagos},
                                      {key:"/gastos/"+inmueble.inmId,value:Strings.gastos},

                                  ]}
                                  onChange={(link)=>history.push(link)}
                    />
            </AppBar>
            <section className={"contentInner "+classes.root} style={isFetching?{opacity:.8}:{opacity:1}}>
                {(isFetching || isPending ) && <LoadingProgress vh/>}

                <MostrarError errors={error} />

                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <div className={classes.imgAvatarCont}
                             {...(images.length && {onClick:zoomImg})}
                        >
                            {/*style={{backgroundImage:`url(${imgAvatar})`}}*/}
                            <img src={imgAvatar} alt=""/>
                            <div className="precio">
                                <div className="published">{inmueble.inmEstadoDescripcion}</div>
                            </div>
                        </div>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} md={8}>
                            <div className={classes.map}>
                                <GoogleMap
                                    center={getLatLng()}
                                    zoom={15}
                                    options={{
                                        disableDefaultUI:true,
                                    }}
                                >
                                    <Marker
                                        position={getLatLng()}
                                    />
                                </GoogleMap>
                            </div>
                        </Grid>
                    </Hidden>
                    <Grid item  xs={12} className={classes.info}>
                        <div className={classes.controls}>
                            <div>
                                {showChart ?(
                                    <Button disabled>{Strings.ingresos}</Button>
                                ):(
                                    <>
                                        {hasPermissionTo(PERSMISOS.inmueblesCambiarEstado) &&
                                            <Button color={inmueble.inmPublico? "primary":"default"}
                                                    endIcon={inmueble.inmPublico?<VisibilityOutlined />:<VisibilityOffOutlined/>}
                                                    onClick={changeEstado}
                                            >
                                                {"Público"}
                                            </Button>
                                        }
                                        {hasPermissionTo(PERSMISOS.inmueblesEliminar) &&
                                            <Button onClick={()=>history.push(history.location.pathname,{modalCancel:urlId})}>
                                                {Strings.eliminar}
                                            </Button>
                                        }
                                        {hasPermissionTo(PERSMISOS.inmueblesActualizar) &&
                                            <Button component={Link} to={`/EditarInmueble/${urlId}`} >
                                                {Strings.editar}
                                            </Button>
                                        }
                                    </>
                                )}

                            </div>

                            <div>
                                <Button onClick={()=>setShowChart(!showChart)} color={showChart ? "primary":"default"}><BarChart/></Button>
                            </div>
                        </div>
                        {!showChart && <div>
                                <p><Typography color={"primary"} component={"span"}>{Strings.tipo}:</Typography> {inmueble.inmTipoDescripcion} / <Typography color={"primary"}  component={"span"}>Código:</Typography> {inmueble.inmReferencia}</p>
                                <p>{inmueble.inmDescripcion}</p>
                                <div className={classes.dirCont}><RoomOutlined fontSize={"small"}/>
                                    {inmueble.inmCoordenadas ?(
                                        <a href={`https://www.google.com/maps?q=${inmueble.inmCoordenadas}&z=17`} target={"_blank"} rel="noopener noreferrer">
                                            {inmueble.inmDireccion + (inmueble.inmDireccion2 ? " / " + inmueble.inmDireccion2 : "")}
                                        </a>
                                    ):(
                                        <span>{(inmueble.inmDireccion || "-") + (inmueble.inmDireccion2 ? " / " + inmueble.inmDireccion2 : "")}</span>
                                    )}
                                </div>
                                <hr />
                                <div className="datos">
                                    <div>
                                        <label>{Strings.venta}: </label>
                                        <span>{formatPeso(inmueble.inmPrecioVenta)}</span>
                                    </div>
                                    <div>
                                        <label>{Strings.alquiler}: </label>
                                        <span>{formatPeso(inmueble.inmPrecioAlquiler)}</span>
                                    </div>
                                    {inmueble.propietario &&
                                    <div>
                                        <label>Propietario: </label>
                                        <span>{inmueble.propietario}</span>
                                    </div>}
                                    {inmueble.inmFechaCreacion &&
                                    <div>
                                        <label>Fecha Registro: </label>
                                        <span>{formatDate(inmueble.inmFechaCreacion)}</span>
                                    </div>}
                                    {inmueble.grupo &&
                                    <div>
                                        <label>Segmento: </label>
                                        <span>{inmueble.grupo}</span>
                                    </div>}
                                    {inmueble.inmAnoConstruccion > 0 &&
                                    <div>
                                        <label>Año construcción: </label>
                                        <span>{inmueble.inmAnoConstruccion}</span>
                                    </div>}
                                    {inmueble.inmHabitaciones > 0 &&
                                    <div>
                                        <label>Habitaciones: </label><span>{inmueble.inmHabitaciones}</span>
                                    </div>}
                                    {inmueble.inmBanos > 0 &&
                                    <div>
                                        <label>Baños: </label><span>{inmueble.inmBanos}</span>
                                    </div>}
                                    {inmueble.inmEstacionamientos > 0 &&
                                    <div>
                                        <label>Parqueos: </label><span>{inmueble.inmEstacionamientos}</span>
                                    </div>}
                                    {inmueble.inmAreaConstruida > 0 &&
                                    <div>
                                        <label>Area construidad: </label><span>{inmueble.inmAreaConstruida}</span>
                                    </div>}
                                    {inmueble.inmAreaTotal > 0 &&
                                    <div>
                                        <label>Area total: </label><span>{inmueble.inmAreaTotal}</span>
                                    </div>}
                                </div>
                                <hr/>
                                <div className="carac">
                                    <Grid component={"ul"} container spacing={1}>
                                        {inmueble.caracteristicas?.map(car =>
                                            <Grid component={"li"} item xs={6} sm={4} key={car.carId}>
                                                {car.carDescripcion}
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>

                                {docs?.length > 0 &&
                                <div className={"d-print-none " + classes.block}>
                                    <h4>{Strings.documento}s</h4>
                                    <ul className={classes.docs}>
                                        {docs.map((obj, i) =>
                                            <li key={i}>
                                                <a href={obj.url} target="_blank" rel="noopener noreferrer">
                                                    <img src={pdfSvg} alt=""/></a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                }
                        </div>}
                        {showChart && <IngresosChart inmuebleId={inmueble.inmId}/>}
                    </Grid>
                </Grid>
            </section>
            {!!urlState?.modalCancel && <CancelDialog inmId={inmueble.inmId}/>}

        </>
    );
}

