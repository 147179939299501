import Inicio  from '../views/Inicio';
import Inicio2  from '../views/Inicio/inicio2';
import Clientes from '../views/Clientes';
import CrearCliente from '../views/Clientes/crear';

import Inmuebles from '../views/Inmuebles';
import CrearInmueble from '../views/Inmuebles/crear';
import DetallesInmuebles from '../views/Inmuebles/detalles';

import Contratos  from '../views/Contratos';
import DetallesContrato from '../views/Contratos/detalles';
import CrearContrato from '../views/Contratos/crear';

import Pagos from '../views/Pagos';
import Gastos from '../views/gastos';


import AppUsers from '../views/appUsers';
import AppUsersEditar from '../views/appUsers/Editar';


import Perfil from '../views/Perfil';
import Agentes from '../views/Agentes';
import Estados from '../views/estados';

import Roles  from '../views/roles';
import InmCaracteristicas from '../views/Caracteristicas';
import ServiciosContrato from '../views/ServiciosContrato';
import UsosMultiples  from '../views/usosMultiples';
import Paises  from '../views/paises';
import Enums  from '../views/enums';

import Ajustes from '../views/Ajustes';
import Ayuda from '../views/ayuda';
import Mensajes from '../views/Mensajes';
import Map from '../views/Map';
import Segmentos from '../views/GrupoInmuebles';

import {SettingsOutlined,AttachMoney,MapOutlined,MoneyOff,DescriptionOutlined,HouseOutlined,
    DashboardOutlined,PeopleAltOutlined,Tune, MessageOutlined,List} from "@material-ui/icons"
import {AccountLockOutline} from "mdi-material-ui";
import {getUsuTipo, hasPermissionTo, PERSMISOS, TIPOUSUARIO} from "../helpers";
const getRoutes = ()=> {
    return ([
        {
            path: "/inicio",
            sidebarName: "inicio",
            icon: DashboardOutlined,
            component: hasPermissionTo(PERSMISOS.dashboard) ? Inicio:Inicio2,

        },
        //Contratos
        {
            path: "/Contratos",
            sidebarName: "contratos",
            title: "Contratos de Alquiler",
            icon: DescriptionOutlined,
            restricted: !hasPermissionTo(PERSMISOS.contratos),
            component: Contratos,
            nestedRoutes: [
                {
                    path: "/Contratos/:cliId",
                    sidebarName: "Contratos",
                    title: "Contratos de Alquiler",
                    component: Contratos
                },
                {
                    path: "/Contrato/:conId",
                    sidebarName: "Detalles",
                    title: "Detalles",
                    component: DetallesContrato
                },
                {
                    path: "/CrearContrato",
                    component: CrearContrato
                },
                {
                    path: "/EditarContrato/:conId",
                    component: CrearContrato
                }
            ]
        },
        //Clientes
        {
            path: "/clientes",
            sidebarName: "clientes",
            title: "clientes",
            icon: PeopleAltOutlined,
            restricted: !hasPermissionTo(PERSMISOS.clientes),
            component: Clientes,
            nestedRoutes: [
                {
                    path: "/CrearCliente",
                    title: "Nuevo cliente",
                    component: CrearCliente
                },
                {
                    path: "/EditarCliente/:cliId",
                    sidebarName: "Editar clientes",
                    title: "Editar clientes",
                    component: CrearCliente
                }
            ]
        },

        //Inmuebles
        {
            path: "/Inmuebles",
            sidebarName: "inmuebles",
            title: "inmuebles",
            icon: HouseOutlined,
            restricted: !hasPermissionTo(PERSMISOS.inmuebles),
            component: Inmuebles,
            nestedRoutes: [
                {
                    path: "/Inmueble/:inmId",
                    sidebarName: "Detalles",
                    title: "Detalles",
                    component: DetallesInmuebles
                },
                {
                    path: "/CrearInmueble",
                    sidebarName: "Nuevo Inmueble",
                    title: "Nuevo Inmueble",
                    component: CrearInmueble
                },
                {
                    path: "/EditarInmueble/:inmId",
                    sidebarName: "Editar Inmueble",
                    title: "Editar Inmueble",
                    component: CrearInmueble
                }
            ]
        },

        //Pagos
        {
            path: "/Pagos",
            sidebarName: "pagos",
            title: "pagos",
            icon: AttachMoney,
            restricted: !hasPermissionTo(PERSMISOS.pagos),
            component: Pagos,
            nestedRoutes: [
                {
                    path: "/Pagos/:inmId",
                    component: Pagos
                },
            ]
        },
        {
            path: "/gastos",
            sidebarName: "gastos",
            icon: MoneyOff,
            navbarName: "gastos",
            restricted: !hasPermissionTo(PERSMISOS.gastos),
            component: Gastos,
            nestedRoutes: [
                {
                    path: "/gastos/:inmId",
                    component: Gastos
                },
            ]
        },


        //Mensajes
        {
            path: "/mensajes",
            sidebarName: "mensajes",
            icon:MessageOutlined,
            restricted: !hasPermissionTo(PERSMISOS.mensajes),
            component: Mensajes
        },
        //GrupoInmuebles
        // {
        //     path: "/segmentos",
        //     sidebarName: "GrupoInmuebles",
        //     title: "GrupoInmuebles",
        //     icon:GroupWorkOutlined,
        //     component: GrupoInmuebles
        // },


        {
            path: "/mapa",
            sidebarName: "mapa",
            navbarName: "mapa",
            restricted: !hasPermissionTo(PERSMISOS.map),
            icon: MapOutlined,
            component: Map
        },
        ...(getUsuTipo() === TIPOUSUARIO.titular || getUsuTipo() === TIPOUSUARIO.admin ?
            [
            //Ajustes
            {
                path: "/Ajustes",
                sidebarName: "ajustes",
                title: "Ajustes",
                icon:SettingsOutlined,
                component: Ajustes
            },{
            sidebarName: "seguridad",
            navbarName: "seguridad",
            icon: AccountLockOutline,
            nestedRoutes: [
                {
                    path: "/Agentes",
                    sidebarName: "agentes",
                    title: "agentes",
                    component: Agentes
                },
                {
                    path: "/Roles",
                    sidebarName: "rolesPermisos",
                    title: "rolesPermisos",
                    component: Roles
                },
            ]
        },
        //Mantenimientos
        {
            sidebarName: "otros",
            navbarName: "otros",
            icon: List,
            nestedRoutes: [
                {
                    path: "/InmCaracteristicas",
                    sidebarName: "caracteristicas",
                    title: "Carácteristicas",
                    component: InmCaracteristicas
                },
                {
                    path: "/serviciosDeContrato",
                    sidebarName: "serviciosContrato",
                    title: "Servicios de contrato",
                    component: ServiciosContrato
                },
                {
                    path: "/segmentos",
                    sidebarName: "grupos",
                    navbarName: "grupos",
                    component: Segmentos
                }
            ]
        }]:[]),

        //Mantenimientos
        ...(getUsuTipo() === TIPOUSUARIO.admin ?
            [{
            sidebarName: "admin",
            navbarName: "admin",
            icon: Tune,
            nestedRoutes: [
                {
                    path: "/appUsers",
                    sidebarName: "appUsers",
                    title: "appUsers",
                    component: AppUsers
                },
                {
                    path: "/appUsers/:usuId",
                    navbarName: "Editar usuario",
                    component: AppUsersEditar
                },
                {
                    path: "/UsosMultiples",
                    sidebarName: "usosMultiples",
                    title: "UsosMultiples",
                    component: UsosMultiples
                },
                {
                    path: "/Estados",
                    sidebarName: "estados",
                    title: "Estados",
                    component: Estados
                },
                {
                    path: "/Paises",
                    sidebarName: "paises",
                    title: "paises",
                    component: Paises
                },
                {
                    path: "/Enums",
                    sidebarName: "enums",
                    navbarName: "enums",
                    component: Enums
                }
            ]
        }]:[]),
        {
            path: "/perfil",
            title: "Perfil",
            component: Perfil
        },
        {
            path: "/ayuda",
            title: "aura",
            component: Ayuda
        },

        {redirect: true, path: "/", to: "/Inicio", title: "Redirect"}
    ].filter(e=> !e.restricted))
}

export default getRoutes;
