// import history  from "../helpers/history";
import store from '../redux/store';
import Notifications from "react-notification-system-redux";
import {errorToNotifObj, getLSLang} from "../helpers";

import { getIdToken } from './auth'; //isLoggedIn
import _ from 'lodash';
import axios from "axios";
import {userLogout} from "../redux/actions/authentication";

export const base_url = "https://api.gestinmueble.com/";//"https://gestinmuebleapi-win.azurewebsites.net/"
export const API = axios.create({
    baseURL: base_url,
    // timeout: 1000,
    headers: {'contentType': 'application/json; charset=utf-8',"accept-language":"es-US"}
})
export const API_PUBLIC = axios.create({
    baseURL: base_url,
    headers: {'contentType': 'application/json; charset=utf-8',"accept-language":"en-US"}
})
API_PUBLIC.interceptors.request.use(function (request) {
    const lang = getLSLang("l") === "en"?"en-US":"es-ES"
    request.headers["accept-language"] = lang;
    return request;
})

API.interceptors.request.use(function (request) {
    const tokenId = getIdToken();
    const lang = getLSLang("l") === "en"?"en-US":"es-ES"
    request.headers["accept-language"] = lang;
    // const decoded = jwt_decode(localStorage._token);
    //
    // const currentTime = Date.now() / 1000;
    // if(decoded.exp < currentTime) {
    //     store.dispatch(logoutUser());
    //     window.location.href = '/login'
    // }
    //
    if (tokenId) request.headers.Authorization = `Bearer ${tokenId}`;

    // if(!window.navigator.onLine) alert("no estas conectado a internet");
    return request;
})
API.interceptors.response.use(function (response) {
    //notificaciones en redux
    let reduxNot = ["getsolicitudes","marcarleida","auth/updatelocation"];
    let url = response.config.url?.toLowerCase();

    if(!!url && !reduxNot.includes(url)){
        let msj = "";
        switch (response.config.method) {
            case "post":
                msj = "Registro guardado";
                break
            case "put":
                msj = "Registro actualizado";
                break
            case "delete":
                msj = "Registro eliminado";
                break
            default:
                msj = "";
        }
        if (msj) {
            store.dispatch(Notifications.warning({ title: msj, autoDismiss: 5}));
        }
    }
    return response;
}, function (error) {
    if (error.response) {

        if (error.response.status === 401) {
            store.dispatch(userLogout())

        } else if (error.response.status === 403) {
            store.dispatch(Notifications.warning({ title: 'Acceso denegado', message: 'Necesita permisos para realizar esta acción.', autoDismiss: 5}));


            // store.dispatch(setNotification({
            //     type: "error",
            //     title: 'Acceso denegado',
            //     message: 'Necesita permisos para realizar esta acción.'
            // }));
        } else {

            store.dispatch(Notifications.error(errorToNotifObj(error.response)));


            // store.dispatch(setNotification({
            //     type: "error",
            //     ...errorToNotifObj(error?.response?.data)
            // }));
        }
    }
    return Promise.reject(error);
});


// API.interceptors.response.use(function (response) {
//     return response;
// }, function (error) {
//     if(error.response){
//         if(error.response.status === 401){
//             localStorage.removeItem('_token');
//             if(history.location.pathname.indexOf("/report") !== -1){
//                 window.close();
//             }
//             history.replace("/login",{backurl:history.location.pathname})
//         }else if(error.response.status === 403){
//             store.dispatch(Notifications.warning({ title: 'Acceso denegado', message: 'Necesita permisos para realizar esta acción.', autoDismiss: 5}));
//         }else{
//             store.dispatch(Notifications.error(errorToNotifObj(error.response)));
//         }
//     }
//     return Promise.reject(error);
// });

//Empresa
export function getGenerales(){
    return API.get('Generales');
}
export function editGenerales(obj){
    let params = _.omitBy(obj, _.isNil);
    return API.put('Generales',params);
}
export function generarBackUp(){
    //   return API.get(`reportes/GenerarBackUp`)
    return axios({
        url: base_url+'reportes/GenerarBackUp',
        method: 'GET',
        responseType: 'blob',
        headers: { 'Accept': 'application/vnd.ms-excel','Authorization': 'Bearer '+ getIdToken(), }
    })
}
export function postGenerales(obj){
    return API.post('Generales',obj);
}
//perfil
export function getPerfil(){
    return API.get('/Perfil');
}
export function ediPerfil(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.put('/Perfil',params);
}
//DashBoard
export function getIngresos(proAnio,inmId) {
    let params = {}
    params.anio = proAnio ? proAnio : new Date().getFullYear();
    if(inmId) params.inmId = inmId;

    return API.get(`/Dashboard/ResumenGrafico`,{params});
}  //getResumenGrafico
export function getWidgets() {
    return API.get(`/Dashboard/Resumen`);
}
export function getCxC() {
    return API.get(`/Reportes/CuentasxCobrar`);
}
//Clientes
export function getClientes(filter, simpleObj){
    if (typeof filter === 'number') {
        return API.get(`Clientes/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get(simpleObj?"Clientes/ListadoSimple":"Clientes",{params});
    }
}
export function addCliente(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Clientes',params);
}
export function editCliente(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.put('Clientes', params);
}
export function delCliente(id) {
    return API.delete(`Clientes/${id}`);
}
export function searchClientes(params) {
    return API.get('Selects/Clientes', { params});
}

//Agente
export function getAgentes(filter){
    if (typeof filter === 'number') {
        return API.get(`Agentes/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get("Agentes",{params});
    }
}
export function addAgente(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Agentes',params);
}
export function editAgente(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Agentes', params);
}
export function editPWAgente(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Agentes/ChangePassword', params);
}
export function delAgente(id) {
    return API.delete(`Agentes/${id}`);
}
export function lockToggleAgente(id){
    return API.post(`Agentes/Lock/${id}`);
}

//Inmuebles
export function getInmuebles(obj){
    if(typeof obj == 'number'){
        return API.get(`Inmuebles/${obj}`);
    }else{
        let params = _.omitBy(obj, (v) => !v);
        return API.get('Inmuebles',{params});
    }
}
export function searchInmuebles(params) {
    return API.get('Selects/Inmuebles', { params });
}
export function addInmueble(obj){
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.post('Inmuebles',params);
}
export function editInmueble(obj){
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.put('Inmuebles',params);
}
export function changeInmPublicar(inmId) {
    return API.post(`Inmuebles/Publicar/${inmId}`);
}
export function delInmueble(id, params) {
    return API.delete(`Inmuebles/${id}`, { params});
}

//Contratos
export function getContratos(filter) {
    if (typeof filter == 'number') {
        return API.get(`Contratos/${filter}`);
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get('Contratos', { params });
    }
}
export function addContrato(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Contratos', params);
}
export function editContrato(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Contratos', params);
}
export function delContrato(id, params) {
    return API.delete(`Contratos/${id}`, { params});
}
export function getConReport(id,plantilla) {
    return API.get(`Contratos/ver/${id}`, { params: { plantilla } });
}
export function addContratoNota(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Contratos/Notas',params);
}
export function delContratoNota(id){
    return API.delete(`Contratos/Notas/${id}`);
}

//pagos
export function getPagos(filter) {
    if (typeof filter == 'number') {
        return API.get(`Pagos/${filter}`);
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get('Pagos', { params });
    }
}
export function addPago(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Pagos', params);
}
export function delPago(id) {
    return API.delete(`Pagos/${id}`);
}

//Mensajes
export function getMensajes(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get("Mensajes", {params});
}
export function msjMarkAsRead(menId) {
    return API.post("/Mensajes/MarcarLeida?menId="+menId);
}

//Grupo Inmuebles
export function getGrupos(name) {
    return API.get('Grupos',{params:{name}});
}
export function addGrupo(obj) {
    return API.post('Grupos', obj);
}
export function editGrupo(obj) {
    return API.put('Grupos', obj);
}
export function delGrupo(id) {
    return API.delete(`Grupos/${id}`);
}

//Gastos
export function getGastos(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Gastos',{params});
}
export function addGasto(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Gastos', params);
}
export function delGasto(id) {
    return API.delete(`Gastos/${id}`);
}
//recibo
export function getReciboPago(pagId) {
    return API.get(`Pagos/GetReciboPago/${pagId}`);
}


/*-------------Otros Mantenimientos--------------------*/
//getUsosMultiples
export function getUsosMultiples(grupo =''){
    return API.get('UsosMultiples/'+grupo);
}
export function addUsosMultiples(obj){
    return API.post('UsosMultiples',obj);
}
export function editUsosMultiples(obj){
    return API.put('UsosMultiples',obj);
}
export function delUsosMultiples(usoGrupo, usoCodigo){
    return API.delete('UsosMultiples',{params:{usoGrupo, usoCodigo}});
}
//Roles
export function getRoles(id){

    if (typeof id == 'number') {
        return API.get(`Roles/${id}`);
    }

    return API.get('Roles');

}
export function addRol(params){
    let obj = _.omitBy(params, (v) => !v);
    return API.post('Roles',obj);
}
export function editRol(params){
    let obj = _.omitBy(params, (v) => !v);
    return API.put('Roles',obj);
}
export function delRol(id){
    return API.delete(`Roles/${id}`);
}

//Servicios de contrato
export function getConServicios() {
    return API.get('Servicios');
}
export function addConServicio(obj) {
    return API.post('Servicios', obj);
}
export function editConServicio(obj) {
    return API.put('Servicios', obj);
}
export function delConServicio(id) {
    return API.delete(`Servicios/${id}`);
}
//Estados
export function getEstados(){
    return API.get('Estados');
}
//permisos
export function getPermisos(){
    return API.get('Permisos');
}
//Paises
export function getPaises(){
    return API.get('comunes/paises');
}
//Enums
export function getEnums(){
    return API.get('comunes/Enums');
}
//notificaciones
export function getNotificaciones(){
    return API.get('Notificaciones');
}

//Caracteristicas de inmuebles
export function getInmCaract() {
    return API.get('Caracteristicas');
}
export function addInmCaract(obj) {
    return API.post('Caracteristicas', obj);
}
export function editInmCaract(obj) {
    return API.put('Caracteristicas', obj);
}
export function delInmCaract(id) {
    return API.delete(`Caracteristicas/${id}`);
}
// //Ajustes
// export function getAjustes() {
//     return API.get('Ajustes');
// }
// export function editAjustes(obj) {
//     return API.post('Ajustes', obj);
// }
// export function editPlantillaContrato(plantilla) {
//     return API.post('Ajustes/Plantilla', { value:plantilla});
// }
// export function editFormatoRecibo(formato) {
//     return API.post('/Ajustes/FormatoRecibo', { value: formato });
// }








// export function getAgentes(params){
//     return API.get('Agentes',{ params });
// }
// export function addAgente(obj) {
//     return API.post('Agentes', obj);
// }
// export function editAgente(obj) {
//     return API.put('Agentes', obj);
// }
// export function lockToggleAgentes(useName){
//     return API.post('Agentes/Lock/'+useName);
// }
// export function delAgente(useName) {
//     return API.delete('Agentes/'+useName);
// }



// //Usuario
// export function getUsuario() {
//     return API.get('Usuario');
// }
// export function editUsuario(obj) {
//     return API.put('Usuario', obj);
// }
// export function changePassUsuario(obj) {
//     return API.post('ChangePassword', obj);
// }
//admin
export function getAppUsers(obj){
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('admin',{params});
}
export function getAppUsersById(id){
    return API.get('admin/GetById/'+id);
}
export function putAppUser(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.put('admin',params);
}
export function getAdminEnums(){
    return API.get('Admin/GetEnums');
}

//auth
export function PostRegister(obj,validate){
    let params = _.omitBy(obj, (v) => !v);
    return API_PUBLIC.post('Register',params);
  //  return API_PUBLIC.post('Register'+(validate?"?validar=true":""),params);
}
export function changePassword(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.post('ChangePassword',params);
}
export function forgotPassword(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.post('ForgotPassword',params);
}
export function forgotPasswordConfirm(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.post('ForgotPasswordConfirm',params);
}




export function getGeo(){
    return axios.get("https://ipapi.co/json");
}
