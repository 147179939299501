import React, {useState} from 'react';
import {
    CircularProgress,
    Dialog,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    makeStyles,
    NativeSelect,
    Typography
} from "@material-ui/core";
import Strings from "../../../assets/strings";
import {getClientes} from "../../../services";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    inputSearch:{
        position: "sticky",
        top: 5,
        zIndex: 1,
        marginBottom: 8,
        "& input":{
            padding: 7,
            width: "calc(100% - 28px)",
            margin: 5,
            marginBottom:0,
            outline:"none"
        }
    },
    loading:{
        width: "13px !important",
        height: "13px !important",
        position: "absolute",
        right: 12,
        top: 15,
    },
    overClick:{
        position: "absolute", height: "100%", width: "100%",zIndex: 1, cursor: "pointer"
    },
    list:{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    subheader: {
        backgroundColor: theme.palette.common.white,
    },
    ul: {
        padding: 0,
    },
}));


let searchTimeout = null;
export default function SearchCliente({filter={}, onChange,value,required,label=Strings.cliente,disabled}) {
    const classes = useStyles();

    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState({});
    const [openDialog, setOpenDialog] = useState(false)


    const handleSearchChange = e =>{
        const {value} = e?.target ?? {};
        setSearch(value || "");
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=> {
            setLoading(true);
            getClientes({...filter,name:search})
                .then(res=>{
                    let results = _.groupBy(res.data?.results ?? [], 'cliTipoDescripcion');
                    setItems(results)
                }).finally(()=>setLoading(false))
        }, 500);

    };
    const handleListItemClick = value => {
        setOpenDialog(false);
        if(onChange)onChange(value);
    };
    const handleOpenDialog = () => {
        setOpenDialog(true);

        if(!Object.keys(items)?.length) handleSearchChange();
    };

    return (
        <>
            <FormControl fullWidth {...((!value) && {required})}  disabled={disabled}>
                <div className={classes.overClick} {...((!disabled) && { onClick: handleOpenDialog})}/>
                <InputLabel style={disabled?{}:{color:"inherit"}}>{label}</InputLabel>
                <NativeSelect value={value}>
                    <option value={value} >{value}</option>
                </NativeSelect>
            </FormControl>
            <Dialog onClose={()=>setOpenDialog(false)}  fullWidth maxWidth={"xs"}  open={openDialog}>
                <div className={classes.inputSearch}>
                    {loading && <CircularProgress className={classes.loading}/>}
                    <input type="text" value={search} placeholder={Strings.buscar} onChange={handleSearchChange}/>
                </div>
                <List className={classes.list} subheader={<li />}>
                    {Object.keys(items).map((property,i) => (
                        <li key={i}>
                            <ul className={classes.ul}>
                                {property !== "null" &&
                                    <ListSubheader className={classes.subheader}>
                                        <Typography color={"primary"} variant={"subtitle1"}>{property}</Typography>
                                    </ListSubheader>
                                }
                                {items[property].map((c)=>
                                    <ListItem button onClick={() => handleListItemClick({cliente:c.cliNombres+" "+c.cliApellidos,cliId: c.cliId})} key={c.cliId}>
                                        <ListItemText primary={c.cliNombres+" "+c.cliApellidos}/>
                                    </ListItem>
                                )}
                            </ul>
                        </li>
                    ))}
                </List>
            </Dialog>
        </>
    );
}
