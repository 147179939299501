import React, {useState, useEffect} from 'react';
import {eliminarCliente, mostrarClienteById} from "../../redux/actions/clientesActions";
import {
    Tab, Tabs, Box, MenuItem, Menu, Paper, ListSubheader,
    ListItem, IconButton, ListItemSecondaryAction, ListItemText, Dialog, List, DialogContent,
    Divider, Typography, useTheme, useMediaQuery
} from '@material-ui/core';
import {Call, EmailOutlined, MoreVert, MapOutlined, WhatsApp} from '@material-ui/icons';
import LoadingProgress from "../../components/LoadingProgress";
import groupBy from 'lodash/groupBy';
import pdfSvg from '../../assets/img/pdf.svg';
import {makeStyles} from "@material-ui/core/styles";
import {Link, useHistory, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {geoString, hasPermissionTo, openNewWin, PERSMISOS} from "../../helpers";
import {SlideLeft} from "../../components/transitions";
import ConfirmDialog from "../../components/ConfirmDialog";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Strings from "../../assets/strings";
import NoImg from '../../assets/img/defaultAvatar.jpg';

const useStyles = makeStyles(theme => ({
    content: {
        padding: "0 !important",
    },
    contactForm: {
        "padding": "8px",
        paddingTop: 16,
        "position": "sticky",
        "bottom": "0",
        "left": "0",
        "width": "calc(100% - 16px)",
        "background": "white",
    },
    title: {
        flexGrow: 1,
        fontWeight: "normal",
    },
    imgCont: {
        width: 150,
        height: 150,
        borderRadius: "50%",
        overflow: "hidden",
        margin: "10px auto",
        "& img": {
            width: "100%",
        }
        // [theme.breakpoints.down('xs')]: {
        //     float:"left",
        //     width:50,
        //     height:50,
        //     marginRight: 12
        // },
    },
    nombre: {
        margin: 0,
        textAlign: "center",
    },
    header: {
        background: "#e6e6e6",
        padding: 10,
    },
    docs: {
        margin: 0,
        marginTop: 10,
        padding: 0,
        listStyle: "none",
        display: "flex",
        "& li": {
            width: 50,
            marginLeft: theme.spacing(2),

            "& img": {
                width: "100%",
            }
        }
    },
    ul: {
        margin: 0,
        marginTop: 10,
        padding: 0,
        listStyle: "none",
        columns: 2,
        "& li": {
            padding: "7px 15px",
            // -webkit-column-break-inside: avoid;
            // page-break-inside: avoid;
            breakInside: "avoid",

            "& h6": {
                fontWeight: "normal",
                margin: 0,
                fontSize: "0.8em",
            }
        },
        [theme.breakpoints.up('sm')]: {
            columns: 3,
        },

    },
    block: {
        borderTop: "1px solid #e6e6e6",
        padding: theme.spacing(2) + "px 0px",

        "& th": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            paddingBottom: "0px !important",
            fontWeight: "normal",
        },
        "& tr": {
            height: 33,
            background: "transparent !important",
            "& a": {
                color: "inherit",
                textDecoration: "none",
            }
        },
        "& h4": {
            fontWeight: "normal",
            margin: "0",
            marginLeft: theme.spacing(2),
            color: theme.palette.secondary.main
        }
    }
}));

const initialState = {
    adjuntos: [],
    referencias: {},
};

function ContactDetails(props) {
    const {push: pushPath ,goBack} = useHistory();
    const {pathname, state} = useLocation();

    const [tab, setTab] = useState(1);

    const [data, setData] = useState(initialState);
    const theme = useTheme();
    const smVewport = useMediaQuery(theme.breakpoints.down('sm'));

    const [showMenu, setShowMenu] = useState(null);

    const classes = useStyles();
    const {byId, isPending,isFetching, eliminarCliente, mostrarClienteById} = props;

    useEffect(() => {
        setTab(1);
        let referencias = {};

        try {
            referencias = groupBy(byId.referencias, function (n) {
                return n.tipo;
            });
        } catch (e) {
        }
        setData({...byId, referencias: referencias});
    }, [setTab, setData, byId]);
    const close = () => {
        mostrarClienteById(false);
        goBack();
    };
    function TabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={1}>{children}</Box>}
            </Typography>
        );
    }


    if(Boolean(!state || !state.cliId)) return null;
    return (
        <>
            <Dialog
                open={true}
                {...(smVewport ? {TransitionComponent: SlideLeft} : false)}
                maxWidth={"sm"}
                fullWidth={true}
                onClose={close}
                fullScreen={smVewport}
            >
                {(isPending || isFetching) && <LoadingProgress/>}

                <TopBarDialog title={Strings.contacto} onClose={close}>
                    {pathname.indexOf("contrato") && //no mostra para evitar problema de goBack
                    <IconButton size={"small"}
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={(e) => setShowMenu(e.currentTarget)}
                                color="inherit"
                    >
                        <MoreVert/>
                    </IconButton>
                    }

                    <Menu
                        id="menu-appbar"
                        anchorEl={showMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(showMenu)}
                        onClose={() => setShowMenu(null)}
                    >
                        {data.cliEstado === 1 && data.cliTipo === "1" && hasPermissionTo(PERSMISOS.contratos) &&
                            <MenuItem component={Link} to={`/Contratos/${data.cliId}`} replace>
                                {Strings.mostrar} {Strings.contrato}
                            </MenuItem>
                        }
                        {hasPermissionTo(PERSMISOS.clientesEliminar) &&
                            <MenuItem
                                onClick={() => {
                                    setShowMenu(null);
                                    pushPath(pathname, {...state, confirmDialog: true});
                                }}
                            >{Strings.eliminar}</MenuItem>
                        }
                        {hasPermissionTo(PERSMISOS.clientesActualizar) &&
                            <MenuItem component={Link} to={`/EditarCliente/${data.cliId}`} replace>{Strings.editar}</MenuItem>
                        }
                        <MenuItem onClick={() => {
                            setShowMenu(null);
                            openNewWin("/reports/cliente/" + data.cliId);
                            //downloadFiles({cliId: data.cliId},"cliente")
                        }}>{Strings.exportar}</MenuItem>

                    </Menu>
                </TopBarDialog>
                <DialogContent className={classes.content}>
                    <header className={classes.header}>
                        <div className={classes.imgCont}>
                            <img src={data.cliAvatar ? data.cliAvatar : NoImg} alt={data.cliNombres}/>
                        </div>
                        <h3 className={classes.nombre}>
                            {data.cliNombres} {data.cliApellidos}
                        </h3>
                    </header>
                    {(Object.keys(data.referencias).length > 0) &&
                        <Tabs
                            value={tab}
                            onChange={(e, v) => setTab(v)}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label={Strings.datos} value={1}/>
                            {Object.keys(data.referencias).length > 0 && <Tab label={Strings.referencias} value={2}/>}
                        </Tabs>
                    }
                    <TabPanel value={tab} index={1}>
                        <div>
                            <List>
                                {data.cliCelular &&
                                <ListItem>
                                    <ListItemText primary={data.cliCelular} secondary={Strings.celular}/>
                                    <ListItemSecondaryAction>
                                        {data?.cliCelular?.startsWith("+") &&
                                        <a href={`https://wa.me/${data.cliCelular?.replace(/[^+\d]+/g, "")}?text=${Strings.hola}, ${data.cliNombres} ${data.cliApellidos}. `}
                                           target={"_blank"} rel="noopener noreferrer">
                                            <IconButton edge="end">
                                                <WhatsApp/>
                                            </IconButton>
                                        </a>
                                        }
                                        <a href={"tel:" + data.cliCelular}>
                                            <IconButton edge="end"><Call/></IconButton>
                                        </a>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                }
                                {data.cliTelefono &&
                                <ListItem>
                                    <ListItemText primary={data.cliTelefono} secondary={Strings.telefono}/>
                                    <ListItemSecondaryAction>
                                        <a href={"tel:" + data.cliTelefono}>
                                            <IconButton edge="end" >
                                                <Call/>
                                            </IconButton>
                                        </a>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                }

                                {data.cliEmail &&
                                <ListItem>
                                    <ListItemText primary={data.cliEmail} secondary={Strings.email}/>
                                    <ListItemSecondaryAction>
                                        <a href={"mailto:" + data.cliEmail}>
                                            <IconButton edge="end">
                                                <EmailOutlined/>
                                            </IconButton>
                                        </a>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                }
                                {!!data.cliDireccion &&
                                <>
                                    <Divider/>
                                    <ListItem>
                                        <ListItemText
                                            primary={data.cliDireccion + (data.cliDireccion2 ? " / " + data.cliDireccion2 : "")}
                                            secondary={Strings.direccion}
                                        />
                                        {!!data.cliCoordenadas &&
                                        <ListItemSecondaryAction>
                                            <a href={`https://www.google.com/maps?q=${data.cliCoordenadas}&z=17`} target={"_blank"} rel="noopener noreferrer">
                                                <IconButton edge="end">
                                                    <MapOutlined/>
                                                </IconButton>
                                            </a>
                                        </ListItemSecondaryAction>
                                        }

                                    </ListItem>
                                </>
                                }
                                <Divider/>
                                <ul className={classes.ul}>
                                    {!!data.cliCedula &&
                                    <li>
                                        <span>{data.cliCedula}</span>
                                        <h6>{data.cliTipo === 2 ? geoString("rnc", true) : Strings.cedula}</h6>
                                    </li>
                                    }
                                </ul>
                            </List>
                        </div>

                        {data.adjuntos?.length > 0 &&
                        <div className={"d-print-none " + classes.block}>
                            <h4>{Strings.documento}s</h4>
                            <ul className={classes.docs}>
                                {data.adjuntos.map((obj, i) =>
                                    <li key={i}>
                                        <a href={obj.url.replace("-small","")} target="_blank" rel="noopener noreferrer">
                                            <img src={obj.url.includes(".pdf") ? pdfSvg : obj.url} alt=""/>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                        }
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Paper elevation={0}>
                            {Object.keys(data.referencias)?.map((i) =>
                                <List dense key={i} subheader={
                                    <ListSubheader color={"primary"}>
                                        {data.referencias[i][0]?.refTipoDescripcion}
                                    </ListSubheader>
                                }>

                                    {data.referencias[i].map((r, i) =>
                                        <ListItem key={i}>
                                            <ListItemText primary={r.refNombres}
                                                          secondary={
                                                              <>
                                                                  {r.refTelefono} <br/>
                                                                  {r.refRelacionDescripcion}
                                                              </>
                                                          }
                                            />
                                            <ListItemSecondaryAction>
                                                <a href={"tel:" + r.refTelefono}>
                                                    <IconButton edge="end"><Call/></IconButton>
                                                </a>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            )}
                        </Paper>
                    </TabPanel>

                    {/*<Backdrop open={true} style={{    position: "absolute",zIndex: 0}}/>*/}


                </DialogContent>
            </Dialog>
            {pathname.indexOf("contrato") === -1 && //no mostra para evitar problema eliminar pago en detalle de contrato
            <ConfirmDialog onConfirm={() => eliminarCliente(data.cliId)}/>
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    byId: state.clientes.byId,
    isPending: state.clientes.isPending,
    isFetching: state.clientes.isFetching,
    fulfilled: state.clientes.fulfilled,
});
export default connect(mapStateToProps, {eliminarCliente, mostrarClienteById})(ContactDetails);
