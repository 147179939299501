import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { mostrarAgentesSinoEsta } from '../../redux/actions/agentesActions';
import {FormControl, InputLabel} from "@material-ui/core";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";

function SelectAgente({value = "", margin, name,required,label, disabled=false, onChange, estTabla, agentes, mostrarAgentesSinoEsta}) {



    const {isFetching,list} = agentes

    const [opciones, setOpciones] = useState([]);

    useEffect(() => {
        mostrarAgentesSinoEsta()
    }, [mostrarAgentesSinoEsta]);

    useEffect(() => {
        if (list.length) {
            setOpciones(
                [
                    {key:"", value: Strings.seleccione},
                    ...list.map(opc=>({key:opc.usuId, value: opc.usuNombres+" "+opc.usuApellidos}))
                ]
            )
        }
    }, [estTabla, setOpciones, list]);


    //const lista = list[group] ? list[group] : [];
    // var options = lista.map(opc=>({key:opc.usoCodigo,value: Strings[_.camelCase(opc.usoDescripcion)] || opc.usoDescripcion}))


    return (
        <FormControl fullWidth required={required} margin={margin} >
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value.toString()}
                onChange={onChange}
                name={name}
                loading={isFetching}
                required={required}
                disabled={disabled}
                opciones={opciones}
            />
        </FormControl>
    );
}

const mapStateToProps = (state) => ({
    agentes: state.agentes,
})
export default connect(mapStateToProps, { mostrarAgentesSinoEsta })(SelectAgente);
