import React, {useState, useEffect} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom'
import {connect} from "react-redux";
import {mostrarContratos} from "../../redux/actions/contratoActions";

import {
    useMediaQuery, Grid, TableBody, TableRow, Button, Dialog, DialogContent,
    DialogActions, TableCell, makeStyles, TableFooter
} from "@material-ui/core";

import {formatPeso, formatDate, hasPermissionTo, PERSMISOS} from '../../helpers';
import downloadFiles from '../../helpers/downloadFiles';
import {
    Pagination,
    TableSort,
    ButtonResponsive,
    SeacrhAppBar,
    LoadingProgress,
    ListViewMode,
    AppBar,
    FilteredAlert,
    MostrarError
} from "../../components";
import ItemContrato from "../../components/CustomListItem/ItemContrato";
import {CloudDownloadOutlined, FilterList} from "@material-ui/icons";
import Strings from '../../assets/strings'
import PlusFab from "../../components/PlusFab";
import SelectEnum from "../../components/selects/SelectEnum";
import SearchCliente from "../../components/selects/searchSelects/SearchCliente";

let initialState={
    name:'',
    estado: '',
    tipo: '',
    cliTipo:'',
    gruId:'',
    //pagination
    page: 0,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
}
const useStyles = makeStyles(() => ({
    name:{
        whiteSpace: "nowrap",
    },
    tRow:{
        cursor: "pointer",
        "&.c":{
            background:"rgba(216, 67, 21, 0.13) !important",
            "& .estado":{
                color: "#d84315",
            }
        },
        "&.m":{
            background:"rgba(255, 111, 0, 0.05) !important",
            "& .estado":{
                color: "#ff6f00",
            }
        },
        "&.p":{
            background:"rgba(25, 118, 210, 0.07) !important",
            "& .estado":{
                color: "#1976d2",
            }
        }
    },
}));
function Contratos(props) {
    const classes = useStyles();
    const {cliId} = useParams();
    initialState = cliId ? {...initialState,cliId,estado:""} : {...initialState,cliId:null,estado:""}; //ver los cancelados de un cliente



    let history = useHistory();
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));

    const [openFilter, setOpenFilter] = useState(false)
    const [filter, setFilter] = useState(initialState);

    const { mostrarContratos,contratos: { list,error, isFetching,filtered} } = props;
    const isEmpty = (!list.results || list.results.length === 0);

    useEffect(() => {
        mostrarContratos(cliId ? {cliId,estado:""} : null);
        return ()=> {
            if(cliId)  mostrarContratos({});
        }
    }, [mostrarContratos,cliId]);
    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


    const handleFilter = e => {
        const {name, value} = e.target;
        if(name === "name" || name === "page" || name === "pageSize") return mostrarContratos({...filter,page:null,[name]:value});

        setFilter({...filter,page:null,[name]:value});
    };
    const resetFilter = () => {
        setOpenFilter(false);
        mostrarContratos({});
    };
    const searchFilter = () => {
        setOpenFilter(false);
        mostrarContratos(filter);
    };
    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=> mostrarContratos({...filter, orderBy,page:null, orderByIsDescending: !filter.orderByIsDescending})
        }
    }

    return (
        <>
            <AppBar title={"Contratos"}>
                <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter}/>
                <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
                <ButtonResponsive style={{display:"none"}} icon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty} onClick={()=>downloadFiles(filter,"contratos")}/>
            </AppBar>
            <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} maxWidth={"xs"}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SelectEnum value={filter.estado} onChange={handleFilter} group={"estadosContrato"} />
                        </Grid>
                        <Grid item xs={12}>
                            <SearchCliente
                                filter={{tipo: 1}}
                                value={filter.cliente??""}
                                label={"Cliente"}
                                onChange={(v)=>{
                                    setFilter(prev => ({...prev,cliId:v.cliId,cliente:v.cliente}))
                                }}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                    <Button color="primary" onClick={searchFilter}>oK</Button>
                </DialogActions>
            </Dialog>
            <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount}>
                <FilteredAlert onClose={resetFilter} filter={filtered}/>
            </ListViewMode>

            <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && isEmpty && <LoadingProgress vh/>}
                <MostrarError errors={error}/>

                {(!props.viewTableList && smVewport)?(
                    !isEmpty && list.results.map(c =>
                        <ItemContrato key={c.conId} item={c}
                                        onClick={() => history.push("/Contrato/" + c.conId)}

                        />
                    )
                ):(
                    <TableSort data={[
                        {pro:"conReferencia",text:Strings.contrato},
                        {pro:"conMontoApagar",text:Strings.monto},
                        {pro:"inmReferencia",text:Strings.referencia},
                        {pro:"cliNombre",text:Strings.cliente},
                        {pro:"conFechaProximoPago",text:Strings.proximo+" "+Strings.pago},
                        {pro:"",text:Strings.estado}
                    ]} onSort={tableSortProps} lessVh={205}>
                        <TableBody>
                            {!isEmpty && list.results.map(item =>
                               <TableRow key={item.conId} onClick={() => history.push("/Contrato/" + item.conId)} className={classes.tRow +" "+item.conEstadoDescripcion?.charAt(0)?.toLowerCase()} >
                                    <TableCell>{item.conReferencia}</TableCell>
                                    <TableCell>{formatPeso(item.conMontoApagar)}</TableCell>
                                    <TableCell>{item.inmReferencia}</TableCell>
                                    <TableCell className={classes.name}>{item.cliNombre}</TableCell>
                                    <TableCell>{formatDate(item.conFechaProximoPago)}</TableCell>
                                    <TableCell>{item.conCuotasPendiente>0 && item.conCuotasPendiente} {Strings[item.conEstadoDescripcion]} </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow  >
                                <TableCell>Total</TableCell>
                                <TableCell>{formatPeso(list?.results?.reduce((a, b) => +a + +b.conMontoApagar, 0) ?? 0)}</TableCell>
                                <TableCell colSpan={4}/>
                                {/*<TableCell className={classes.name}>{item.cliNombre}</TableCell>*/}
                                {/*<TableCell>{formatDate(item.conFechaProximoPago)}</TableCell>*/}
                            </TableRow>
                        </TableFooter>
                    </TableSort>

                )}

                <Pagination
                    empty={Boolean(isEmpty && !isFetching)}
                    count={list.totalRecordCount || 0}
                    rowsPerPage={filter.pageSize}
                    page={list.pageNo -1}
                    onChangePage={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
                    onChangeRowsPerPage={handleFilter}
                />
            </section>
            {hasPermissionTo(PERSMISOS.contratosCrear) &&
                <PlusFab component={Link} to="/CrearContrato" />
            }
        </>
    );
}
const mapStateToProps = (state) => ({
    contratos: state.contratos,
    viewTableList: state.theme.viewTableList
})
export default connect(mapStateToProps, { mostrarContratos})(Contratos);

